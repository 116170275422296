<template>
  <div class="row">
    <div class="col-12 col-lg-8 c">
      <div class="card card-body">
        <div class="col-12 table-responsive">
          <table class="table table-hover">
            <thead>
              <th>العنوان</th>
              <th>عدد الارقام</th>
              <th>خيارات</th>
            </thead>
            <tbody>
              <tr v-for="group in groups" :key="group._id">
                <td>
                  {{ group.title }}
                </td>
                <td>
                  {{ group.contacts }}
                </td>
                <td>
                  <b-dropdown
                    id="dropdown-1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    text="خيارات"
                    variant="primary"
                    size="sm"
                  >
                    <b-dropdown-item
                      @click="
                        $router.push(
                          '/contacts#' + (group._id ? group._id : 'empty')
                        )
                      "
                    >
                      <i class="fa fa-users"></i>
                      عرض الارقام
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-b-modal.modal-2
                      @click="current = group"
                      v-if="group._id != ''"
                    >
                      <i class="fa fa-link"></i>
                      رابط لاستقبال <br />الارقام
                    </b-dropdown-item>
                    <b-dropdown-item disabled v-if="group._id == ''">
                      <i class="fa fa-link"></i>
                      رابط لاستقبال <br />الارقام
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-b-modal.modal-1
                      @click="current = group"
                      v-if="group._id != ''"
                    >
                      <i class="fa fa-edit"></i>
                      تعديل
                    </b-dropdown-item>
                    <b-dropdown-item disabled v-if="group._id == ''">
                      <i class="fa fa-edit"></i>
                      تعديل
                    </b-dropdown-item>
                    <b-dropdown-item @click="deleteGroup(group._id)">
                      <i class="fa fa-trash"></i>
                      حذف
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- basic modal -->
    <b-modal id="modal-1" title="تعديل المجموعة" hide-footer>
      <div class="form-group">
        <label for="">العنوان</label>
        <input type="text" class="form-control" v-model="current.title" />
      </div>
      <div class="col-12 text-center">
        <button class="btn btn-success" @click="editGroupCurrent()">
          تعديل <i class="fa fa-arrow-left"></i>
        </button>
      </div>
    </b-modal>
    <!-- basic modal -->
    <b-modal id="modal-2" title="تعديل المجموعة" hide-footer>
      <div class="form-group">
        <label for="">رابط الاستقبال</label>
        <input
          type="text"
          class="form-control"
          readonly
          dir="ltr"
          :value="'https://app.smart-whats.com/rg/' + current._id"
        />
      </div>
      <div class="form-group">
        <label for="">اقصى عدد للمجوعة عند استقبال الارقام</label>
        <input
          type="number"
          class="form-control"
          v-model="current.max_contacts"
        />
      </div>
      <div class="form-group">
        <label for="">تحديد الدولة للارقام المدخلة</label>
        <select class="form-control" v-model="current.country">
          <option :value="undefined">-- عدم التحديد --</option>
          <option value="sa">السعودية</option>
          <option value="eg">مصر</option>
        </select>
      </div>
      <div class="form-group">
        <label for="">الرسالة الموجودة في صفحة صفحة الاستقبال</label>
        <textarea
          class="form-control"
          v-model="current.message"
          rows="3"
        ></textarea>
      </div>
      <div class="col-12 text-center">
        <button class="btn btn-success" @click="editGroupCurrent()">
          حفظ <i class="fa fa-arrow-left"></i>
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BOverlay,
  BModal,
  VBModal,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BModal,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data() {
    return {
      loading: false,
      groups: [],
      current: {},
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    var g = this;
    $.post(api + "/user/contacts/groups/list-full", {
      jwt: this.user.jwt,
    })
      .then(function (r) {
        g.groups = r.response;
        g.loading = false;
      })
      .catch(function () {
        g.loading = false;
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدثت مشكلة في الاتصال",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      });
  },
  methods: {
    deleteGroup(id) {
      if (confirm("متأكد من حذف المجموعة والارقام التي بداخلها؟")) {
        var g = this;
        $.post(api + "/user/contacts/groups/delete", {
          jwt: this.user.jwt,
          id: id,
        })
          .then(function (r) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "تم الحذف بنجاح",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            location.reload();
          })
          .catch(function () {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدثت مشكلة",
                icon: "XIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    editGroupCurrent() {
      var g = this;
      var g = this,
        current = g.current;
      $.post(api + "/user/contacts/groups/edit", {
        jwt: this.user.jwt,
        id: current._id,
        title: current.title,
        max_contacts: current.max_contacts,
        message: current.message,
        country: current.country
      })
        .then(function (r) {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "تم التعديل بنجاح",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          $("#modal-1___BV_modal_header_ > button").click();
          location.reload();
        })
        .catch(function () {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدثت مشكلة",
              icon: "XIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>

<style>
.select-way-to-add:hover {
  background: #eee !important;
  cursor: pointer;
}
</style>